import {
  Button,
  Flex,
  HStack,
  Text,
  useBreakpointValue,
  Spinner,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { RiMenuLine } from 'react-icons/ri';
import { useHomePageSidebarDrawer } from '../../../contexts/HomePageSidebarDrawerContext';
import { IconButton } from '../../Buttons/IconButton';

export default function LoginSection() {
  const [isRedirecting, setIsRedirecting] = useState(false);
  const { onOpen } = useHomePageSidebarDrawer();
  const router = useRouter();

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });
  return (
    <HStack align="center" width="100%" justify="flex-end">
      {!isWideVersion && (
        <IconButton
          aria-label="Open navigation"
          remixIcon={RiMenuLine}
          fontSize="24"
          variant="unstyled"
          onClick={onOpen}
          display="flex"
        />
      )}
      {/* <ToogleThemeButton /> */}
      <Button
        colorScheme="back"
        onClick={() => {
          router.push('/login');
          setIsRedirecting(true);
        }}
      >
        <Flex align="center">
          <Flex direction="column" color="gray.900">
            Entrar
            <Text fontSize="x-small">ou criar conta</Text>
          </Flex>
          {isRedirecting && (
            <Spinner marginLeft="2" size="xs" thickness="3px" />
          )}
        </Flex>
      </Button>
    </HStack>
  );
}
