import { Flex, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { LogoImage } from '../../Header/Logo';
import { Sidebar } from '../Sidebar';
import LinksSection from './LinksSection';
import LoginSection from './LoginSection';

export default function Header() {
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  return (
    <Flex
      as="header"
      w="100%"
      h="20"
      mx="auto" // Margem na horizontal
      mt="4" // Margem top
      px="6"
      align="center"
      maxH="20"
      backgroundColor="gray.900"
    >
      <Sidebar />
      <Flex align="center" width="100%" marginBottom="4" minHeight="10">
        <LogoImage isHomePage />
        {isWideVersion && <LinksSection />}
      </Flex>
      <LoginSection />
    </Flex>
  );
}
