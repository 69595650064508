import Image from 'next/image';
import { Heading, Flex, useBreakpointValue } from '@chakra-ui/react';

export default function FirstImpressions() {
  const headingSize = useBreakpointValue({
    base: '2xl',
    lg: '2xl',
  });

  return (
    <Flex
      width="100%"
      align="center"
      justify="center"
      paddingBottom="4"
      backgroundColor="gray.900"
    >
      <Flex width="90%" marginTop="4" maxWidth="1960px">
        <Flex
          width="100%"
          justify="center"
          direction="column"
          align="center"
          marginBottom={['4', '8', '12', '16']}
        >
          <Heading
            as="h2"
            size={headingSize}
            fontWeight="extrabold"
            letterSpacing="tight"
            textAlign="center"
            marginTop={['0', '0', '4', '8']}
            marginBottom={['4', '8', '12', '16']}
            color="gray.50"
          >
            Dê o próximo passo no seu Trade Esportivo
          </Heading>
          <Image
            alt="Devices"
            unoptimized={process.env.NODE_ENV === 'development'}
            priority={true}
            src="/devices.png"
            width="1200"
            height={600}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
