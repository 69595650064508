import Link from 'next/link';
import Image from 'next/image';

import { Link as ChakraLink, Text } from '@chakra-ui/react';

interface LogoImageProps {
  isHomePage?: boolean;
  isSideBar?: boolean;
}

export function LogoImage({
  isHomePage = false,
  isSideBar = false,
}: LogoImageProps) {
  return (
    <Link href={isHomePage ? '/' : '/dashboard'} passHref>
      <ChakraLink>
        {/* <AspectRatio ratio={1} minWidth="20"> */}
        <Image
          // boxSize={['10', '20']}
          src="/logo.jpeg"
          alt="Layback"
          width={isSideBar ? 60 : 80}
          height={isSideBar ? 60 : 80}
          unoptimized={process.env.NODE_ENV === 'development'}
          priority={true}
          // mr={['3', '4']}
        />
        {/* </AspectRatio> */}
      </ChakraLink>
    </Link>
  );
}

export function LogoText() {
  return (
    <Link href="/dashboard" passHref>
      <Text
        as="a"
        fontSize={['2xl', '3xl']}
        fontWeight="bold"
        letterSpacing="tight"
        w="64"
        color="danger.500"
      >
        LAY
        <Text
          as="span"
          fontSize={['2xl', '3xl']}
          fontWeight="bold"
          letterSpacing="tight"
          color="back.500"
        >
          BACK
        </Text>
      </Text>
    </Link>
  );
}
