import Image from 'next/image';

import {
  Flex,
  Text,
  Heading,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';

export default function Benefits() {
  const isSmallerVersion = useBreakpointValue({ base: true, md: false });
  const textMaxWidth = useBreakpointValue({ base: '90%', md: '50%' });
  return (
    <Flex
      width="100%"
      align="center"
      justify="center"
      backgroundColor="back.500"
      id="benefits"
    >
      <Flex
        width="90%"
        marginTop="4"
        maxWidth="1960px"
        marginBottom={['4', '8', '12', '16']}
      >
        <Flex width="100%" justify="center" direction="column" align="center">
          <Flex
            marginY={['4', '8']}
            direction="column"
            width={['100%', '80%', '80%', '60%']}
          >
            <Heading
              as="h2"
              size="2xl"
              fontWeight="extrabold"
              letterSpacing="tight"
              marginBottom="4"
              textAlign="center"
              color="gray.900"
            >
              Vantagens
            </Heading>
            <Text
              marginBottom="4"
              color="gray.900"
              textAlign="center"
              fontSize="small"
              paddingX="4"
            >
              Nosso sistema é baseado nos seus métodos do trade esportivo, para
              você ter mais clareza no que você é bom e onde você tem que
              melhorar.
            </Text>
          </Flex>
          <Flex
            width={['100%', '80%', '80%', '60%']}
            align="center"
            justify="center"
            direction={isSmallerVersion ? 'column' : 'row'}
          >
            <Flex
              width={textMaxWidth}
              marginY="4"
              marginX="4"
              marginBottom="8"
              borderRadius="8"
              direction="column"
              align="center"
              color="gray.900"
            >
              <Text
                marginBottom="4"
                fontSize="xl"
                fontWeight="bold"
                textAlign="center"
              >
                Classificação automática das operações
              </Text>
              <Image
                alt="Import orders gif"
                src="/classify-operations.gif"
                height="450"
                width="1280"
                unoptimized={process.env.NODE_ENV === 'development'}
                priority={true}
              />
            </Flex>
            <VStack
              direction="column"
              spacing="4"
              marginBottom={isSmallerVersion ? '4' : '4'}
              maxWidth={textMaxWidth}
              color="gray.900"
              paddingX="4"
            >
              <Text align="justify">
                Para que você não tenha que ficar classicando operação por
                operação, uma por uma, nós temos uma funcionalidade de
                classificação automática de operações. No momento de cadastrar
                um método, você adiciona de maneira específica alguns parâmetros
                (como odd mínima e máxima, qual mercado opera nesse método, qual
                stake utilizada). Depois, é só apertar um botão e nós
                classificamos todas as operações que dão &quot;match&quot; com
                esses parâmetros. Fácil né?
              </Text>
            </VStack>
          </Flex>
          <Flex
            width={['100%', '80%', '80%', '60%']}
            align="center"
            justify="center"
            direction={isSmallerVersion ? 'column' : 'row'}
          >
            <Flex
              width={textMaxWidth}
              marginY="4"
              marginX="4"
              marginBottom="8"
              borderRadius="8"
              direction="column"
              align="center"
              color="gray.900"
            >
              <Text
                marginBottom="4"
                fontSize="xl"
                fontWeight="bold"
                textAlign="center"
              >
                Importação automática das operações
              </Text>
              <Image
                alt="Expired"
                src="/import-orders-gif.gif"
                height="600"
                width="1280"
                unoptimized={process.env.NODE_ENV === 'development'}
                priority={true}
              />
            </Flex>
            <VStack
              direction="column"
              spacing="4"
              marginBottom={isSmallerVersion ? '4' : '4'}
              maxWidth={textMaxWidth}
              color="gray.900"
              paddingX="4"
            >
              <Text align="justify">
                Se você usa planilha ou algo parecido para analisar os
                resultados do seu trade você já deve estar cansado de anotar uma
                por uma, operação por operação. No Layback Dashboard, devido a
                nossa integração com a própria Betfair, importar as últimas
                operações é tão simples quanto apertar um botão!
              </Text>
            </VStack>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
