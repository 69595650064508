import { Button } from '@chakra-ui/react';
import Image from 'next/image';
import {
  StatHelpText,
  StatLabel,
  StatNumber,
  Flex,
  Text,
  Heading,
  VStack,
  useBreakpointValue,
  Stat,
  Spinner,
} from '@chakra-ui/react';

import { useRouter } from 'next/router';
import { useState } from 'react';

export default function Pricing() {
  const [isRedirecting, setIsRedirecting] = useState(false);
  const isSmallerVersion = useBreakpointValue({ base: true, md: false });

  const router = useRouter();

  return (
    <Flex
      width="100%"
      align="center"
      justify="center"
      backgroundColor="gray.900"
      paddingBottom="4"
      id="pricing"
    >
      <Flex
        width="90%"
        marginTop="4"
        maxWidth="1960px"
        color="gray.50"
        marginBottom={['4', '8', '12', '16']}
      >
        <Flex width="100%" justify="center" direction="column" align="center">
          <Flex
            marginY={['4', '8']}
            direction="column"
            width={['100%', '80%', '80%', '60%']}
          >
            <Heading
              as="h2"
              size="2xl"
              fontWeight="extrabold"
              letterSpacing="tight"
              textAlign="center"
              marginBottom="4"
            >
              Preço
            </Heading>
            <Text marginBottom="4" textAlign="center" fontSize="small">
              Todos os usuário tem direito a <strong>30 dias gratuitos</strong>{' '}
              do Layback Dashboard para testar a plataforma.
            </Text>
          </Flex>
          <Flex
            width={['100%', '80%', '80%', '60%']}
            align="center"
            justify="center"
            direction={isSmallerVersion ? 'column' : 'row'}
          >
            <Flex
              maxHeight="460px"
              width="50%"
              marginY="4"
              borderRadius="8"
              align="center"
              justifyContent="center"
            >
              <Image
                alt="Expired"
                src="/FreeTrial.svg"
                width="200"
                height="400"
                unoptimized={process.env.NODE_ENV === 'development'}
                priority={true}
              />
            </Flex>
            <VStack
              direction="column"
              spacing="4"
              marginBottom={isSmallerVersion ? '4' : '0'}
              width="50%"
            >
              <Stat
                backgroundColor="gray.800"
                paddingY="4"
                paddingX="2"
                borderRadius="8"
                align="center"
              >
                <StatLabel>Plano Mensal</StatLabel>
                <StatNumber marginY="2">R$ 9,90</StatNumber>
                <StatHelpText>
                  Aceitamos cartão de crédito, PIX, transferência e boleto
                  bancário, cobrado só após o período gratuito.
                </StatHelpText>
                <Button
                  colorScheme="back"
                  onClick={() => {
                    router.push('/login');
                    setIsRedirecting(true);
                  }}
                  marginY="4"
                >
                  <Flex align="center">
                    Testar por 30 dias
                    {isRedirecting && (
                      <Spinner marginLeft="2" size="xs" thickness="3px" />
                    )}
                  </Flex>
                </Button>
              </Stat>
            </VStack>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
