import { Flex } from '@chakra-ui/react';
import {
  RiAddBoxLine,
  RiFunctionLine,
  RiLock2Line,
  RiMoneyDollarCircleLine,
  RiQuestionLine,
} from 'react-icons/ri';
import { AnchorLink } from './AnchorLink';

interface LinksSectionProps {
  isHorizontal?: boolean;
}

export default function LinksSection({
  isHorizontal = true,
}: LinksSectionProps) {
  return (
    <Flex
      align={isHorizontal ? 'center' : 'flex-start'}
      direction={isHorizontal ? 'row' : 'column'}
      whiteSpace="nowrap"
      marginLeft="4"
    >
      <AnchorLink
        icon={RiQuestionLine}
        href="/#what-is"
        isHorizontal={isHorizontal}
      >
        O que é
      </AnchorLink>
      <AnchorLink
        icon={RiFunctionLine}
        href="/#functionalities"
        isHorizontal={isHorizontal}
      >
        Funcionalidades
      </AnchorLink>
      <AnchorLink
        icon={RiLock2Line}
        href="/#security"
        isHorizontal={isHorizontal}
      >
        Segurança
      </AnchorLink>
      <AnchorLink
        icon={RiMoneyDollarCircleLine}
        href="/#pricing"
        isHorizontal={isHorizontal}
      >
        Preço
      </AnchorLink>
      <AnchorLink
        icon={RiAddBoxLine}
        href="/#benefits"
        isHorizontal={isHorizontal}
      >
        Vantagens
      </AnchorLink>
    </Flex>
  );
}
