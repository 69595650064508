import { ButtonGroup, ButtonGroupProps, IconButton } from '@chakra-ui/react';
import { RiInstagramLine, RiWhatsappLine } from 'react-icons/ri';

export default function SocialMediaLinks(props: ButtonGroupProps) {
  return (
    <ButtonGroup variant="ghost" color="gray.600" {...props}>
      <IconButton
        as="a"
        href="https://api.whatsapp.com/send?phone=5522999223821&text=Ol%C3%A1!%20"
        target="_blank"
        aria-label="WhatsApp"
        icon={<RiWhatsappLine fontSize="20px" />}
      />
      <IconButton
        as="a"
        href="https://www.instagram.com/laybacktrade/"
        target="_blank"
        aria-label="Instagram"
        icon={<RiInstagramLine fontSize="20px" />}
      />
    </ButtonGroup>
  );
}
