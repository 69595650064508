import { Flex } from '@chakra-ui/react';
import Head from 'next/head';
import Functionalities from '../components/HomePage/Functionalities';
import Header from '../components/HomePage/Header';
import FirstImpressions from '../components/HomePage/FirstImpressions';
import WhatIs from '../components/HomePage/WhatIs';
import Security from '../components/HomePage/Security';
import Pricing from '../components/HomePage/Pricing';
import Footer from '../components/Footer';
import Benefits from '../components/HomePage/Benefits';
import CallToAction from '../components/HomePage/CallToAction';

export default function Home() {
  return (
    <div>
      <Head>
        <title>Layback</title>
      </Head>

      <Flex direction="column" h="100vh" backgroundColor="gray.900">
        <Header />
        <Flex width="100%" direction="column" justify="center" align="center">
          <FirstImpressions />
          <WhatIs />
          <Functionalities />
          <Security />
          <Pricing />
          <Benefits />
          <CallToAction />
        </Flex>
        <Footer />
      </Flex>
    </div>
  );
}
