import Image from 'next/image';
import {
  Flex,
  Text,
  Heading,
  Link,
  useColorModeValue,
  VStack,
  Icon,
  useBreakpointValue,
} from '@chakra-ui/react';
import { RiExternalLinkLine } from 'react-icons/ri';

export default function WhatIs() {
  const isSmallerVersion = useBreakpointValue({ base: true, md: false });
  const linkColor = useColorModeValue('back.500', 'back.500');
  return (
    <Flex
      width="100%"
      align="center"
      justify="center"
      backgroundColor="gray.300"
      id="what-is"
    >
      <Flex
        width="90%"
        marginTop="4"
        maxWidth="1960px"
        marginBottom={['4', '8', '12', '16']}
      >
        <Flex width="100%" justify="center" direction="column" align="center">
          <Heading
            as="h2"
            size="2xl"
            fontWeight="extrabold"
            letterSpacing="tight"
            marginY={['4', '8']}
            textAlign="center"
            color="gray.900"
          >
            O que é
          </Heading>
          <Flex
            width={['100%', '80%', '80%', '60%']}
            align="center"
            direction={isSmallerVersion ? 'column' : 'row'}
          >
            <VStack
              direction="column"
              spacing="4"
              marginBottom={isSmallerVersion ? '4' : '0'}
              width={['100%', '60%']}
            >
              <Text align="left" color="gray.900">
                O Layback Dashboard é um gerenciador de operações da Betfair.
                Com uma integração <strong>simples, rápida e segura</strong>,
                importamos todas as suas operações feitas no{' '}
                <strong>Exchange</strong> da Betfair. Tudo de forma automática e
                em <strong>questão de segundos</strong>. Sempre que você tiver
                novas operações na Betfair, basta apertar um botão que todas
                serão importadas.
              </Text>
              <Text align="left" color="gray.900">
                O nosso principal objetivo é que você consiga ter mais clareza
                do seu Trade, no que você é bom e no que você pode melhorar.
                Além disso, usamos{' '}
                <Link
                  href="https://www.youtube.com/watch?v=jaGUNVLaGx0&ab_channel=Nettuno-TraderEsportivo"
                  passhref
                  fontWeight="bold"
                  color={linkColor}
                  isExternal
                >
                  o mesmo conceito de filiais{' '}
                  <Icon as={RiExternalLinkLine} mx="2px" />
                </Link>{' '}
                (ou métodos) que o Nettuno tanto aborda em seus vídeos.
              </Text>
            </VStack>
            <Flex
              maxHeight="460px"
              margin="4"
              align="center"
              justify="center"
              width={['100%', '60%']}
            >
              <Image
                alt="Expired"
                src="/iPhone-SE.svg"
                height="460"
                width="300"
                unoptimized={process.env.NODE_ENV === 'development'}
                priority={true}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
