import {
  // Link as ChakraLink,
  // LinkProps as ChakraLinkProps,
  Icon,
  Text,
  Flex,
} from '@chakra-ui/react';
import { ElementType } from 'react';

// import { ActiveAnchorLink } from './ActiveAnchorLink';

interface AnchorLinkProps {
  icon: ElementType;
  children: string;
  href: string;
  isHorizontal?: boolean;
}

export function AnchorLink({
  icon,
  children,
  href,
  isHorizontal = true,
  ...rest
}: AnchorLinkProps) {
  return (
    <Flex
      as="button"
      onClick={() => {
        const id = href.split('#')[1];
        document.querySelector(`#${id}`).scrollIntoView({ behavior: 'smooth' });
      }}
      display="flex"
      alignItems="center"
      _hover={{
        color: 'back.500',
        cursor: 'pointer',
        borderBottom: isHorizontal ? '2px solid' : '',
        paddingBottom: isHorizontal ? '3px' : '',
        borderLeft: isHorizontal ? '' : '2px solid',
        paddingLeft: isHorizontal ? '' : '3px',
        transitionDuration: '200ms',
      }}
      marginRight={isHorizontal ? '6' : '0'}
      marginBottom={isHorizontal ? '' : '6'}
      {...rest}
    >
      <Icon as={icon} fontSize="20" marginRight="2" color="gray.50" />
      <Text fontWeight="medium" marginBottom="1" color="gray.50">
        {children}
      </Text>
    </Flex>
  );
}
