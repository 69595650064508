import Image from 'next/image';

import {
  Flex,
  Text,
  Heading,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';

export default function Security() {
  const isSmallerVersion = useBreakpointValue({ base: true, md: false });
  const textMaxWidth = useBreakpointValue({ base: '90%', md: '50%' });
  return (
    <Flex
      width="100%"
      align="center"
      justify="center"
      backgroundColor="back.500"
      id="security"
    >
      <Flex width="90%" maxWidth="1960px">
        <Flex
          width="100%"
          justify="center"
          direction="column"
          align="center"
          marginBottom={['4', '8', '12', '16']}
          marginTop="4"
        >
          <Heading
            as="h2"
            size="2xl"
            fontWeight="extrabold"
            letterSpacing="tight"
            marginY={['4', '8']}
            textAlign="center"
            color="gray.900"
          >
            Segurança
          </Heading>
          <Flex
            width={['100%', '80%', '80%', '60%']}
            align="center"
            justify="center"
            direction={isSmallerVersion ? 'column' : 'row'}
          >
            <VStack
              direction="column"
              spacing="4"
              marginBottom={isSmallerVersion ? '4' : '4'}
              maxWidth={textMaxWidth}
              color="gray.900"
              paddingX="4"
            >
              <Text align="justify">
                Todas as informações que importamos da sua conta são autorizadas
                e fiscalizados pela própria Betfair. Todo o processo de login é
                feito pela Betfair, em nenhum momento entramos em contato com
                informacões sensíveis suas como login ou senha.
              </Text>
              <Text align="justify">
                No momento da criação da sua conta a Betfair irá solicitar sua
                autorização (foto ao lado) para que possamos importar as
                informações da sua conta. A qualquer momento você pode alterar
                as permissões do Layback a sua conta.
              </Text>
            </VStack>
            <Flex width={textMaxWidth} marginY="4" marginX="4" borderRadius="8">
              <Image
                alt="Expired"
                src="/permissions.jpeg"
                height="924"
                width="1280"
                unoptimized={process.env.NODE_ENV === 'development'}
                priority={true}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
