import { Button } from '@chakra-ui/react';
import {
  Flex,
  Text,
  Heading,
  useColorModeValue,
  Icon,
  useBreakpointValue,
  Spinner,
} from '@chakra-ui/react';
import { darken, lighten } from '@chakra-ui/theme-tools';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { RiWhatsappLine } from 'react-icons/ri';

export default function CallToAction() {
  const [isRedirecting, setIsRedirecting] = useState(false);
  const whatsAppButtonHoverColor = useColorModeValue(
    lighten('#25D366', 10),
    darken('#25D366', 10)
  );

  const headingSize = useBreakpointValue({
    base: '2xl',
    lg: '2xl',
  });

  const router = useRouter();
  return (
    <Flex
      width="100%"
      align="center"
      justify="center"
      backgroundColor="gray.900"
      paddingBottom="4"
    >
      <Flex width="90%" marginTop="4" maxWidth="1960px" color="gray.50">
        <Flex width="100%" justify="center" direction="column" align="center">
          <Flex
            width={['100%', '80%', '80%', '60%']}
            direction="column"
            align="center"
          >
            <Heading
              as="h2"
              size={headingSize}
              fontWeight="extrabold"
              letterSpacing="tight"
              textAlign="center"
              marginY={['4', '8']}
            >
              Experimente por 30 dias grátis
            </Heading>
            <Button
              colorScheme="back"
              onClick={() => {
                router.push('/login');
                setIsRedirecting(true);
              }}
              marginY="12"
              size="lg"
            >
              <Flex align="center">
                Testar por 30 dias
                {isRedirecting && (
                  <Spinner marginLeft="2" size="xs" thickness="3px" />
                )}
              </Flex>
            </Button>
            <Text fontSize="sm" width="70%" textAlign="center">
              Caso tenha alguma dúvida, reclamação ou sugestão para o Layback
              Dashboard, por favor, entre em contato conosco pelo nosso
              WhatsApp.
            </Text>
            <Flex width="100%" align="center" justify="space-evenly">
              <Flex direction="column">
                <Button
                  mt="2"
                  as="a"
                  href="https://api.whatsapp.com/send?phone=5522999223821&text=Ol%C3%A1!%20"
                  target="_blank"
                  size="sm"
                  backgroundColor="#25D366"
                  color="white"
                  _hover={{
                    backgroundColor: whatsAppButtonHoverColor,
                  }}
                  fontWeight="bold"
                  maxWidth="620px"
                >
                  <Flex align="center">
                    <Icon as={RiWhatsappLine} marginRight="2" fontSize="20" />
                    <Text fontSize={['md']} lineHeight="5">
                      Entrar em contato
                    </Text>
                  </Flex>
                </Button>
                <Text fontWeight="light" fontSize="sm" marginTop="1">
                  Segunda a sexta, 08h00 às 18h00.
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
