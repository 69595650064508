import { Box, Stack, useColorModeValue } from '@chakra-ui/react';
import { Copyright } from './Copyright';
import SocialMediaLinks from './SocialMediaLinks';

export default function Footer() {
  const borderColor = useColorModeValue('gray.600', 'gray.400');
  return (
    <Box
      as="footer"
      role="contentinfo"
      marginTop="auto"
      py="6"
      px={{ base: '4', md: '8' }}
    >
      <Stack borderTopWidth=".5px" borderTopColor={borderColor} paddingTop="4">
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing="5"
          align="center"
          justify="space-between"
        >
          <Copyright
            alignSelf={{ base: 'center', md: 'start' }}
            textAlign={{ base: 'center', md: 'start' }}
            lineHeight={{ base: '', md: '10' }}
          />
          <SocialMediaLinks />
        </Stack>
      </Stack>
    </Box>
  );
}
