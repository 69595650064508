import {
  Flex,
  Grid,
  Text,
  Heading,
  useBreakpointValue,
  Icon,
} from '@chakra-ui/react';
import {
  RiCalendarLine,
  RiDownloadLine,
  RiFlag2Line,
  RiLockLine,
  RiPriceTag3Line,
  RiRobotLine,
  RiShieldLine,
  RiSmartphoneLine,
} from 'react-icons/ri';

export default function Functionalities() {
  const columnsQuantity = useBreakpointValue({ base: 1, md: 2 });
  return (
    <Flex
      width="100%"
      align="center"
      justify="center"
      backgroundColor="gray.900"
      color="gray.50"
      id="functionalities"
    >
      <Flex
        width="90%"
        marginTop="4"
        maxWidth="1960px"
        marginBottom={['4', '8', '12', '16']}
      >
        <Flex width="100%" justify="center" direction="column" align="center">
          <Heading
            as="h2"
            size="2xl"
            fontWeight="extrabold"
            letterSpacing="tight"
            marginY={['4', '8']}
            textAlign="center"
          >
            Funcionalidades
          </Heading>
          <Flex
            width={['100%', '80%', '80%', '60%']}
            align="center"
            justify="center"
          >
            <Grid
              templateColumns={`repeat(${columnsQuantity}, 1fr)`}
              gap={['6', '12']}
            >
              <Flex align="center" justify="left">
                <Icon
                  as={RiDownloadLine}
                  fontSize="40"
                  marginRight="6"
                  color="back.500"
                />
                <Text textAlign="justify">
                  Importação automática das entradas do{' '}
                  <strong>Exchange da Betfair</strong>. Adeus planilhas!
                </Text>
              </Flex>
              <Flex align="center" justify="left">
                <Icon
                  as={RiSmartphoneLine}
                  fontSize="40"
                  marginRight="6"
                  color="back.500"
                />
                <Text textAlign="justify">
                  <strong>Acessível e responsivo</strong> para qualquer
                  dispositivo (Celular, tablet ou notebook/desktop);
                </Text>
              </Flex>
              <Flex align="center" justify="left">
                <Icon
                  as={RiShieldLine}
                  fontSize="40"
                  marginRight="6"
                  color="back.500"
                />
                <Text textAlign="justify">
                  <strong>Informações detalhadas</strong> de todos os times que
                  você operou no período selecionado;
                </Text>
              </Flex>
              <Flex align="center" justify="left">
                <Icon
                  as={RiPriceTag3Line}
                  fontSize="40"
                  marginRight="6"
                  color="back.500"
                />
                <Text textAlign="justify">
                  <strong>Analise comparativa</strong> dos métodos utilizados,
                  facilitando o entendimento do seu trade;
                </Text>
              </Flex>
              <Flex align="center" justify="left">
                <Icon
                  as={RiFlag2Line}
                  fontSize="40"
                  marginRight="6"
                  color="back.500"
                />
                <Text textAlign="justify">
                  <strong>Análise financeira</strong> entre os campeonatos e
                  países das partidas que você operou;
                </Text>
              </Flex>
              <Flex align="center" justify="left">
                <Icon
                  as={RiRobotLine}
                  fontSize="40"
                  marginRight="6"
                  color="back.500"
                />
                <Text textAlign="justify">
                  <strong>Classificação automática</strong> das operações nos
                  seus métodos de acordo com os parâmetros de cada método;
                </Text>
              </Flex>
              <Flex align="center" justify="left">
                <Icon
                  as={RiCalendarLine}
                  fontSize="40"
                  marginRight="6"
                  color="back.500"
                />
                <Text textAlign="justify">
                  Controle o período analisado como quiser, possibilitando
                  análises semanais, mensais, anuais, etc. Você no controle!
                </Text>
              </Flex>
              <Flex align="center" justify="left">
                <Icon
                  as={RiLockLine}
                  fontSize="40"
                  marginRight="6"
                  color="back.500"
                />
                <Text textAlign="justify">
                  Suas operações ficam salvas de forma <strong>segura</strong> e{' '}
                  <strong>para sempre</strong>.
                </Text>
              </Flex>
            </Grid>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
